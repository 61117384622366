
.App-header {
  background-image: linear-gradient(#2E506C, #152337);
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header {

  border:none !important;
}

.modal-title {
  font-size: 18px !important;
}

.modal-body {
  padding: 0px !important;
}

.modal-body p {
  font-size: 13px !important;
}

.d-flex h3 {
  margin-left: 10px;
}

.btn-app {
  background-image: linear-gradient(#FAFF03, #FFFFFF);
}

.btn-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}


.text-center {
  text-align: center !important;
  margin: 0px 5px;
}


.btn-active-account2 {
  background-color: #FFFFFF;
  border: none;
  color: #333333;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  border-radius: 100px;
  font-size: 18px;
  text-align: left;
}







.main-login {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.left-login-box {
  background-color: #2A3F54;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;


}

.text {
  padding-left: 0%;
}

.login-wrapper {
 
  margin: 20px 0px 140px 0px;

}

ul {
  direction: rtl; /* This makes the bullet points and text align to the right */
  text-align: right; /* This ensures the text aligns to the right */
}

li {
  list-style-position: inside; /* This ensures the bullet points appear inside the content box */
}

.login-wrapper .title {
  color: #fff;
  margin-bottom: 15px;
}

.login-wrapper input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.login-wrapper2 {
 
  margin: 20px 0px 0px 0px;

}

.login-wrapper2 .title {
  color: #fff;
  margin-bottom: 15px;
}

.login-wrapper2 input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}




.forgot-password {
  text-align: right;
  font-size: 15px;
  color: #FCFDFF;
}

.forgot-password a {
  color: #FCFDFF;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.btn-signin,
.btn-create-account {
  background-color: #478CD1;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  /* margin: 10px 0; */
  border-radius: 5px;
  font-size: 15px;
}

.btn-create-account {
  background-color: #4BB869;
}

.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7FDBCD;
  font-size: 12px;
  text-decoration: none;
}

.main-register {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.left-register-box {
  background-color: #2A3F54;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;



}

.register-wrapper {
  margin: 80px auto;

}

.register-wrapper .title {
  color: #fff;
  margin-bottom: 15px;
}

.register-wrapper input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.register-wrapper2 {
  margin: 20px 0px 0px 0px;

}

.register-wrapper2 .title {
  color: #fff;
  margin-bottom: 15px;
}

.register-wrapper2 input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}


.fieldset {
  width: 500px;
  margin: auto;
  padding: 10px 40px;
  

}

.fieldset2 {
  width: 500px;
  margin: auto;
  padding: 10px 40px;
  

}

.wallet {
  background-color: #2A3F54;
  padding: 30px 30px 0 30px;
  border-radius: 20px;
  margin: 20px auto;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 12%;
}

.wallet2 {
  background-color: #2A3F54;
  padding: 30px 30px 0 30px;
  border-radius: 20px;
  margin: 20px auto;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 12%;

}


.hh1 {
  font-size: 32px;
  font-weight: bold;
}

.hh2 {
  font-size: 20px;
  font-weight: normal;

}

.wallet p {
  color: #FFFFFF;
  font-size: 14px;
}

.wallet2 p {
  color: #FFFFFF;
  font-size: 14px;
}

.cricket-image {
  max-width: 100%;
  position: relative;
  left: 70px;
}

.btn-download {
  background-image: linear-gradient(#FAFF03, #FFFFFF);
  border: none;
  color: #082546;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 20px;
}


.account {
  margin: auto;
  background: #617688;
  padding: 20px 0px 20px 0px;
  border-radius: 10px;

}

.account3 {
  margin: auto;
  background: #617688;
  padding: 20px;
  border-radius: 10px;

}




.account2 {
  margin: auto;
  background: #617688;
  padding: 20px 20px;
  border-radius: 10px;

}

.account-login {
  margin: auto;
  background: #2A3F54;
  padding: 20px 15px;
  border-radius: 10px;
}

.left-login-box1 {
  background-color: #2A3F54;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
}

.left-login-box2 {
  background-color: #FFFFFF;
  border-radius: 10px;

}

.cus-btn1 {
  color: #333333 !important;
  background: #fff !important;
  border-radius: 100px !important;
  padding: 6px 30px !important;
  font-size: 18px !important;
  margin: 1px 0px;
  display: flex; /* Use Flexbox for centering content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflow content */
  text-align: center; /* Center text horizontally within the button */
}

.cus-btn1 .copy-txt {
  display: flex; /* Use Flexbox within the button */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 0; /* Remove default margins if any */
}

.cus-btn1 img {
  margin-left: 10px; /* Space between text and image */
  width: 20px; /* Set image width */
  height: 20px; /* Set image height */
  display: inline-block; /* Ensure image aligns with text */
}



.cus-btn3 {
  color: #333333 !important;
  background: #fff !important;
  border-radius: 100px !important;
  padding: 6px 57px !important;
  font-size: 18px !important;
  margin: 0px 3px;
  display: flex; /* Use Flexbox for centering content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflow content */
  text-align: center; /* Center text horizontally within the button */
}

.cus-btn3 .copy-txt {
  display: flex; /* Use Flexbox within the button */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 0; /* Remove default margins if any */
}

.cus-btn3 img {
  margin-left: 10px; /* Space between text and image */
  width: 20px; /* Set image width */
  height: 20px; /* Set image height */
  display: inline-block; /* Ensure image aligns with text */
}



.cus-btn3 h5 {
  margin: 2px 3px; /* Remove default margin from h5 */
  text-align: center; /* Center text within h5 */
}



.cus-btn2 {
  color: #333333 !important; /* Text color */
  background: #fff !important; /* Background color */
  border: 1px solid #ddd; /* Border color and width */
  border-radius: 100px !important; /* Rounded corners */
  padding: 8px 40px !important; /* Padding inside the button */
  font-size: 18px !important; /* Font size */
  margin: 1px 10px; /* Margin around the button */
  display: flex; /* Use Flexbox for alignment */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
  text-align: center; /* Center text horizontally within the button */
  cursor: pointer; /* Cursor pointer on hover */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transitions */
}

.cus-btn1 h5 {
  margin: 2px 3px; /* Remove default margin from h5 */
  text-align: center; /* Center text within h5 */
}

.cus-btn3 h5 {
  margin: 2px 3px; /* Remove default margin from h5 */
  text-align: center; /* Center text within h5 */
}

.cus-btn2:hover {
  background: #478CD1; /* Background color on hover */
  color: #fff; /* Text color on hover */
  border-color: #478CD1; /* Border color on hover */
}

.btn-whatappp {
  background-color: #FFFFFF;
  border: none;
  color: #333333;
  padding: 7px 20px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  border-radius: 100px;
  font-size: 18px;
}

.withdraw {
  display: flex;
  justify-content: center;
}

.btn-active-account {
  background-color: #FFFFFF;
  border: none;
  color: #333333;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  border-radius: 100px;
  font-size: 18px;
  text-align: left;
}

.btn-active-website {
  background-image: linear-gradient(#2E506C, #152337);
  border: none;
  color: #FFFFFF;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  border-radius: 100px;
  font-size: 18px;
text-align: center;
}

.btn-active-website2 {
  background-image: linear-gradient(#2E506C, #152337);
  border: none;
  color: #FFFFFF;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
  border-radius: 100px;
  font-size: 18px;
text-align: center;
}

.right {
  float: right;
}


.image-wrapper-left img {
  width: 150px;
}



.footer-wrapper {
  background-image: linear-gradient(#2E506C, #152337);
  padding: 20px 30px;
  overflow: hidden;
}



.footer-hed {
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 500;

}


.cpoyright {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

hr {
  color: #FFFFFF !important;
  opacity: 1.25 !important;
}

.st1 {
  margin-left: 6%;
}

.st2 img {
  padding: 14px 0;
}

.cpoyright p {
  color: #FFFFFF;
  font-size: 16px;
}

.stores img {
  max-height: 80px;
  vertical-align: middle;
}

.deposit-text h5 {

  font-size: 18px;
}



.cus-navb {
  display: flex;
  justify-content: center;
}

.image {
  text-align: center;
  margin: auto;
}

/* .main-wrapper-nav {
  background-image: linear-gradient(#2E506C, #152337);
  padding: 20px 0;
  overflow: hidden;
} */

.main-wrapper-nav {
  background-image: linear-gradient(#2E506C, #152337);
  padding: 10px 0;
  position: fixed; /* Make the nav bar fixed to the screen */
  bottom: 0; /* Stick it to the bottom */
  left: 0;
  width: 100%; /* Ensure it spans the full width */
  z-index: 1000; /* Make sure it stays on top of other content */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); /* Optional: Adds a shadow effect */
}


.nav-link {
  color: #FFFFFF !important;

  font-weight: 600 !important;
}

.image:hover a.nav-link {
  color: #4BB869 !important;

}
.image:hover{
  color: #4BB869 ;
}
.image .icon:hover{
  color: #4BB869 ;
}

.image .icon2:hover{
  color: #4BB869 ;
}

.nav-link{
display: flex !important;
align-items: center !important;
flex-direction: column !important;
gap: 10px !important;
}
.nav-link:hover{
  color: #4BB869 !important;
  .icon{
    fill: #4BB869 !important;
  }
  .icon2{
    fill: #4BB869 !important;
  }

}
.main-account {
  background-color: #34b747;
  color: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 15px;
}

.whatappp h5 {
  margin: auto;
}

.main-deposit-wraper {
  margin: 20px 0px 140px 0px;
}

.deposit-easypaisa {
  background-color: #D9D9D9;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  height: 80px;
  cursor: pointer;

  position: relative; /* Allows absolute positioning of the checkmark */
}

.checkmark {
  position: absolute;
  top: -5px;
  right: 4px;
  border-radius: 50%;
  padding: 5px;
}

.checkmark img {
  padding: 0px !important;
  width: 35px;
  height: 30px;
}

.deposit {
  padding: 10px;
}

.deposit-text {
  background-color: #478CD1;
  text-align: center;
  color: #FFF;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
}

.deposit-easypaisa img {
  padding-top: 30px;
}

.middle-depsoit {
  background-color: #2A3F54;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
}

.fieldset-deposit {
  width: 600px;
  margin: auto;
  padding: 10px 34px;
  margin-bottom: 150px;

}

.fieldset-deposit h2 {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}

.fieldset-deposit h3 {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}

.amount-detail-number {
  text-align: right;
  font-size: 20px;

}

.wallet-number {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 10px;


}

.price-btn {
  color: #333333 !important;
  background: #fff !important;
  border-radius: 100px !important;
  padding: 10px 30px;
  font-size: 18px ;
  margin: auto;
  border: 1px solid #fff;
}

.price-btn span {
  font-weight: bold;
  color: #2A3F54;
}

.confirm-btn {
  background-color: #4BB869;
  border: none;
  color: #FFFFFF;
  padding: 10px 40px !important;
  cursor: pointer;
  margin: 0 auto !important;
  width: auto !important;
  border-radius: 100px;
  font-size: 18px;
}

.ic-copy {
  margin-left: 20px;
  font-size: 20px;
}
.account-btn{
  color: #333333 !important;
  background: #fff !important;
  border-radius: 100px !important;
  padding: 6px 15px !important;
  font-size: 18px !important;
  margin: 0 10px;
  border: none;
}


.icon{
  fill: white ;
}
.icon :hover{
  fill: #4BB869;
}

.icon2{
  fill: white ;
}
.icon2 :hover{
  fill: #4BB869;
}

.link-active .nav-link{
  color: #4BB869 !important;
}
.link-active .icon{
  fill: #4BB869 !important;
}

.link-active .icon2{
  fill: #4BB869 !important;
}


.modal.show .modal-dialog .modal-content{
  background-color:  #2A3F54;
  color: white;
  font-size: 14px;
}
.modal-title {
  margin: 0 auto;
}

.modal.show .modal-dialog .modal-content .modal-body {
  text-align: center;
  padding: 1rem 1px;
}

.model-ok{
  cursor: pointer;
}
.forgotinputs{
  margin: 30px;
}
.forgotinputs input{
  width: 100%;
padding: 10px;
border-radius: 5px;
border: none;
}

.ic-copy svg{
  cursor: pointer;
  margin-bottom: 5px;
 
}
.copy-icon{
  margin-top: 0px;
}
.copy-txt {
  display: flex;
  align-items: center; /* Centers items vertically within the flex container */
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from breaking into multiple lines */
  overflow: hidden; /* Hides overflow content if it exceeds the container width */
}

.copy-txt .text {
  display: flex;
  align-items: center; /* Ensures text is vertically centered with the image */
  margin-right: 10px; /* Space between text and image */
}

.copy-txt img {
  width: 20px; /* Adjust based on your design */
  height: 20px; /* Adjust based on your design */
}

#fileId {
  display: none;
}
#amount{
  width: 190px;
  height: 50px;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.currency-symbol {
  position: absolute;
  left: 45px; 
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 18px;
  font-weight: bold;
}

.input-wrapper input {
  padding-left: 30px; 
}


.p-active {
  background-color: #4BB869 !important; 
  color: #FFF !important;
  font-weight: bold !important;
}
.deposit-easypaisa input[type="radio"] {
  opacity: 0; 
  position: absolute; 
}


.method-active{

    background-color: #4BB869;
    font-weight: bold;
    color: #ffffff;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
}

.custom-input-wrapper {
  background-color: #f0f0f0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between; /* Space between the symbol and input */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure the wrapper takes up full width if needed */
}

.custom-currency-symbol {
  font-size: 18px;
  color: #333333;
  margin-left: 20px;
  white-space: nowrap; /* Prevents line break for currency symbol */
}

.custom-amount-input {
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 16px;
  text-align: right;
  flex-grow: 1; /* Allows input to take up available space */
  min-width: 0; /* Ensures the input shrinks with screen size */
}

.custom-amount-input:focus {
  outline: none;
}

.image-wrapper-right img {
  width: 70%;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .image-wrapper-left img{
    width: 100px;
  
  }
  .image-wrapper-right img {
    width: 70%;
  }
  .text {
    padding-left: 0%;}

    .cricket-image {
      max-width: 190%;
      position: relative;
      top: 10px;
      left: -50px;
    }

    .wallet2 {
  
      margin-bottom: 20%;
      
    }

    .footer-hed {
     
      font-size: 20px;
      font-weight: 500;
  }
  
       
}




@media only screen and (max-width: 768px) {

  .image-wrapper-left img {
    width: 100px;
  }

  .footer-hed {
     
    font-size: 16px;
    font-weight: 500;
}


  .fieldset {
    width: 453px;
    
}

.fieldset2 {
  width: 453px;
  
}

  .image-wrapper-right img {
    width: 70%;
  }

  .wallet2 {
  
    margin-bottom: 25%;
    
  }

  /* .main-wrapper-nav {
    position: sticky !important;
    bottom: 0;
  } */

  .icon{
    height: 25px !important;
    width: 25px !important;
  }

  .icon2{
    height: 25px !important;
    width: 25px !important;
  }

  .nav-link{
    font-size: small !important;
  }
  .fieldset-deposit {
    width: auto !important ;
    margin-bottom: 120px;

  }
  .fieldset-deposit h3 {

    font-size: 15px !important 
}
.wallet-number {
  font-size: 14px !important ;
}
.cus-btn1 {
  padding: 10px 30px !important; /* Reduce padding for smaller screens */
   font-size: 10px !important; /* Adjust font size */
   width: 100%;
   margin: auto;
 }

 .cus-btn3 {
  padding: 6px 30px !important; /* Reduce padding for smaller screens */
   font-size: 10px !important; /* Adjust font size */
   width: 100%;
   margin: 0px 3px !important
 }


 .cus-btn1 img {
   width: 15px; /* Reduce image size */
   height: 15px; /* Reduce image size */
 }

 .cus-btn1 .copy-txt {
   font-size: 10px; /* Adjust text size */
 }

 .cus-btn1 h5 {
    
  font-size: 20px !important; /* Adjust font size */

}

.cus-btn3 h5 {
    
  font-size: 20px !important; /* Adjust font size */

}
.mb-view{
  width: 172px !important;
  margin-left: -30px !important;
  height: 45px !important;
}
.confirm-btn {
  width: auto !important;
  margin: 0 auto !important;;
}

.price-btn {
  padding: 10px 14px !important;
  font-size: 15px;
}




.btn-whatappp h5 {
  margin-left: 2px;
  
}

.btn-whatappp h5 img , svg {
  margin-right: 5px;
}


.info p {
  font-size: 14px !important;
}

.text .hh1 {

  font-size: 20px;
}

.text .hh2 {

  font-size: 16px;
}

.text p {

  font-size: 14px;
}




.cricket-image {
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 50px;
}




}


.dn_btn_txt {
  font-weight: 700;
  
}


.btn-download {
  font-weight: 700;
}


@media only screen and (max-width: 576px) {

  .ic-copy {
    margin-left: 5px;
    font-size: 0px;

  }


  .modal-body p {
    font-size: 10px;
  }
  .App-header {
   
    padding: 10px;
   
  
}

  .dn_btn_txt {
    font-size: 10px;
    
  }


  .d-flex h3{
    font-size: 14px !important;
  
  }


  li {
    font-size: 10px;
  }


  .account2 data {

    font-size: 8px !important;

  }


  .account2 {
    
   margin: auto;
    padding: 10px 15px;
    border-radius: 10px;
}


  .account2 p {
    font-size: 12px !important;
    padding: 0px 0px 10px 0px;
}


  h3 {
    font-size: 20px !important;
  
  }


  h2 {
    font-size: 18px !important;
  }

  .forgot-password a {
  
    font-size: 10px;
   
}


.register-wrapper2 input {
 
  padding: 10px;
  font-size: 12px;
  
 
}

.btn-signin, .btn-create-account {
 
  padding: 10px 20px;
  font-size: 12px;

}


.login-wrapper2 input {
  padding: 10px;
  font-size: 14px;
}


  .paycardgird {

    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

   }


   .paycardgird2 {

    position: relative;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    left: 0px;
    right: 0px;

   }

   .paycardgird2 .col-3 {
    display: flex;
   }
   

   

  .fieldset {
    width: 100%;
    padding: 10px 25px;
  }

  .fieldset2 {
    width: 100%;
    padding: 10px 20px;
  }

  .text {
    padding-left: 0;
  }

  .cus-btn1 {
    padding: 4px 22px !important;
    font-size: 16px !important;
}

.cus-btn3 {
  padding: 4px 22px !important;
  font-size: 16px !important;
  margin: 0px 3px !important
}


  .cus-btn1 h5 {
    
     font-size: 12px !important; /* Adjust font size */
   
   }

   
  .cus-btn3 h5 {
    
    font-size: 12px !important; /* Adjust font size */
  
  }

  .cus-btn1 img {
    width: 15px; /* Reduce image size */
    height: 15px; /* Reduce image size */
  }

  .cus-btn1 .copy-txt {
    font-size: 10px; /* Adjust text size */
  }

  #amount{
    width: 150px !important;
  }
  .currency-symbol {

    left: 25px !important; 
    top: 51% !important;
  }
  .account-btn{
    width: -webkit-fill-available;
    font-size: 12px !important;
  }
  .fieldset-deposit {
    width: auto !important;
    margin-bottom: 120px;

  }

  .footer-hed {
    font-size: 12px !important;
}

.cpoyright p {
  color: #FFFFFF;
  font-size: 8px;
}

.image-wrapper-right img {
  width: 70%;
}
  .deposit-easypaisa {
    height: 50px;
    border-radius: 5px 6px 0px 0px;
    width: 90px;
   
}
/* .deposit-easypaisa img {
  width: 50px;
  padding-top: 17px;
} */

.deposit-easypaisa img {
  width: 59px;
  padding-top: 21px;
}


  .deposit-text h5 {
    font-size: 10px;
  }

  .deposit-text {
    padding: 5px 0px 0px 0px;
    font-weight: bold;
    /* font-size: 8px !important; */
    border-radius: 0px 0px 5px 5px;
    width: 90px;
   
    
}

/* .checkmark {
 
  top: -12px;
  right: -4px;
  
} */


.checkmark {
  top: -9px;
  right: -2px;
}

.checkmark img {
  width: 20px;
  height: 20px;
}



/* .checkmark img {
  
  width: 20px;
  height: 15px;
} */


.row {
  align-items: center;
}


  .price-btn {
    padding: 10px 14px !important;
    font-size: 12px;
  }
  .cus-btn1 {
    padding: 5px 25px !important;
    font-size: 16px !important;
}

.cus-btn3 {
  padding: 5px 25px !important;
  font-size: 16px !important;
}
  .mb-view {
    width: 140px !important;
    margin-left: -30px !important;
    height: 40px !important;
}
  .mb-5 {
    margin-bottom: 2rem !important;
}
/* .main-wrapper-nav {
  position: sticky !important;
  bottom: 0;
} */
.icon {
  height: 18px !important;
  width: 18px !important;
}

.icon2 {
  height: 22px !important;
  width: 22px !important;
}

.nav-link{
  font-size: 11px !important;
}
.fieldset-deposit h3 {

  font-size: 12px !important ;
}
.wallet-number {
  font-size: 12px !important ;
}
.confirm-btn {
  width: auto !important;
  margin: 0 auto !important;
  font-size: 12px;
}

.btn-active-account h5 {

  font-size: 14px;
}

.btn-active-account2 h5 {

  font-size: 11px;
}




.btn-active-website h5 {

  font-size: 16px;
}


.btn-active-website2 h5 {

  font-size: 12px;
}

.btn-whatappp h5 {
  margin-left: 2px;
  font-size: 12px;
}

.btn-whatappp h5 img , svg {
  margin-right: 5px;
}


.info p {
  font-size: 12px !important;
}


.text .hh1 {

  font-size: 18px;
}

.text .hh2 {

  font-size: 14px;
}

.text p {

  font-size: 12px;
}


.cricket-image {
  max-width: 100%;
  position: relative;
  top: -100px;
  left: 89px;
 
}

.btn-download {
  padding: 8px 14px;
  border-radius: 100px;
  margin-top: 20px;
  font-size: 10px;
}

.wallet {
 
  padding: 30px 30px 0 30px;
  
  
}


.wallet2 {
  padding: 30px 30px 0px 30px;
  height: 504px;
  margin-bottom: 113px;
}



.custom-input-wrapper {
  justify-content: space-between; /* Adjust for smaller screens */
  height: 35px !important;
}

.custom-amount-input {
  font-size: 14px;
  max-width: 100px; /* Reduce max-width on smaller screens */
}

.custom-currency-symbol {
  font-size: 14px;
 
}

.image-wrapper-left img {
  width: 100px;
}

.modal-title {
  font-size: 14px !important;
}


.btn-active-account2 span {
  font-size: 11px;
}

}


@media only screen and (max-width: 390px) {


  .paycardgird2 {

    position: relative;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    right: 5px;
    left: -5px;
    width: 104%;

   }
  }



.scrollable-transactions {
  transform: scale(1);
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.scrollable-transactions::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scrollable-transactions.card-expanded {
  transform: scale(1.1); /* Card grows when scrolling */
}


/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* General */
input[type="number"] {
  appearance: textfield;
}





